import React from "react"
import { Link } from "gatsby"
import SEO from "../components/SEO/SEO"

import Layout from "../components/layout"
import Container from "../components/container/container"

const NotFoundPage = () => (
  <Layout mainClassName="main--404">
    <SEO
      title="Oops, sorry we can't find that page!"
      desc="Either something went wrong or the page doesn't exist anymore."
      canonical="https://tr069.cloud/404/"
      pathname="/404"
      />
    <section className="error-404">
      <Container>
        <h1 className="error-404__title">
          404
        </h1>
        <p className="error-404__line1">Oops, sorry we can't find that page!</p>
        <p className="error-404__line2">Either something went wrong or the page doesn't exist anymore.</p>
        <Link className="error-404__btn" to="/">Home page</Link>
      </Container>
    </section>
  </Layout>
)

export default NotFoundPage
